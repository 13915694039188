var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { position, bottom, width, maxWidth, height, left, backgroundColor, color, boxShadow, size, overflow, display, borderBottom, minHeight, gridColumn, } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import Icon from '../../common/components/Icon';
import { ResponsiveLazyImage } from '../../common/components/LazyImage';
import { Text } from '../../common/components/Text';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import { useIsObserved } from '../../common/hooks/intersection-observer';
import { themeTop, boxSizing, cursor, whiteSpace, textOverflow, textTransform, borderBottomColor, } from '../../common/theme/system-utilities';
var ICON_SIZE = 24;
var IMAGE_HEIGHT = ['168px', '324px', '340px', '348px'];
var TeaserGridItem = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), gridColumn);
var Teaser = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, height, color, boxShadow, cursor);
Teaser.defaultProps = {
    as: 'a',
    position: 'relative',
    justifyContent: 'center',
    width: 1,
    height: ['295px', '324px', '348px'],
    color: 'sys.neutral.border.active',
    boxShadow: ['0 2px 6px 0 rgba(192, 192, 192, 0.5)', 'none'],
    cursor: 'pointer',
};
var Image = styled(ResponsiveLazyImage)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  object-fit: cover;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  object-fit: cover;\n"])), width, height, display);
Image.defaultProps = {
    display: 'block',
    width: 1,
    height: IMAGE_HEIGHT,
};
var InformationBoxWrapper = styled(Flex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, themeTop, bottom, left, boxSizing);
InformationBoxWrapper.defaultProps = {
    boxSizing: 'border-box',
    position: 'absolute',
    themeTop: ['auto', 'sp_16', 'sp_24', 'sp_32'],
    bottom: [0, 'auto'],
    flexDirection: 'column',
    alignItems: 'center',
    width: 1,
};
var InformationBox = styled(Flex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, boxSizing, minHeight);
InformationBox.defaultProps = {
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: ['center', 'flex-start'],
    alignItems: ['center', 'flex-start'],
    px: 'sp_16',
    py: ['sp_16', 'sp_8'],
    backgroundColor: 'sys.neutral.background.default',
    maxWidth: ['100%', '85%'],
    width: 1,
    minHeight: ['127px', 'auto'],
};
var EllipsisParagraph = styled(Text)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), maxWidth, whiteSpace, overflow, textOverflow);
EllipsisParagraph.defaultProps = {
    as: 'p',
    mt: 0,
    mb: 0,
    maxWidth: '100%',
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: ['lh_24', null, null, 'lh_28'],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};
var Category = styled(EllipsisParagraph)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), borderBottom, borderBottomColor, textTransform);
Category.defaultProps = __assign(__assign({}, EllipsisParagraph.defaultProps), { borderBottom: '3px solid', borderBottomColor: 'sys.neutral.background.inverted', textTransform: 'uppercase', mb: ['sp_8', 'sp_12'] });
var Title = styled(EllipsisParagraph)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
Title.defaultProps = __assign(__assign({}, EllipsisParagraph.defaultProps), { fontSize: ['fs_18', null, null, 'fs_20'], fontWeight: 'bold', lineHeight: ['lh_28', null, null, 'lh_32'], mb: ['sp_4', 'sp_0'] });
var Subtitle = styled(Flex)(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
Subtitle.defaultProps = {
    justifyContent: ['center', 'inherit'],
    alignItems: 'center',
    width: 1,
};
var StyledIcon = styled(Icon)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), size);
StyledIcon.defaultProps = {
    size: ICON_SIZE,
};
var getTeaserImage = function (_a) {
    var isEnhanced = _a.isEnhanced, image = _a.image, enhancedImage = _a.enhancedImage;
    if (!isEnhanced) {
        return image;
    }
    return __assign(__assign({}, enhancedImage), { large_desktop: (image === null || image === void 0 ? void 0 : image.large_desktop) || (image === null || image === void 0 ? void 0 : image.desktop) });
};
var InspirationalTeaserItem = function (_a) {
    var category = _a.category, title = _a.title, subtitle = _a.subtitle, image = _a.image, enhancedImage = _a.enhancedImage, link = _a.link, tracking = _a.tracking, isEnhanced = _a.isEnhanced, index = _a.index;
    var teaserImage = getTeaserImage({
        isEnhanced: isEnhanced,
        image: image,
        enhancedImage: enhancedImage,
    });
    var _b = __read(useIsObserved(), 2), ref = _b[0], isObserved = _b[1];
    var _c = usePromoEvents(__assign(__assign({}, tracking), { position: index })), onPromoView = _c.onPromoView, onPromoClick = _c.onPromoClick;
    useEffect(function () {
        if (isObserved) {
            onPromoView();
        }
    }, [isObserved]);
    return (React.createElement(TeaserGridItem, __assign({ ref: ref, key: "inspirational-teaser-".concat(index), "data-testid": "inspirational-teaser-".concat(index), "data-type": isEnhanced ? 'enhanced' : 'regular' }, (isEnhanced && { gridColumn: [1, 'span 2', null, 3] })),
        React.createElement(Teaser, __assign({}, (isEnhanced && {
            justifyContent: ['center', 'flex-start', null, 'center'],
        }), { "data-prefetch": true, href: link, onClick: onPromoClick }),
            React.createElement(Image, __assign({}, teaserImage)),
            React.createElement(InformationBoxWrapper, __assign({}, (isEnhanced && {
                pr: ['sp_0', 'sp_8', 'sp_12', 'sp_0'],
                width: [1, 1 / 2, null, 1],
            })),
                React.createElement(InformationBox, null,
                    category && React.createElement(Category, null, category),
                    React.createElement(Title, null, title),
                    React.createElement(Subtitle, null,
                        React.createElement(EllipsisParagraph, null, subtitle),
                        React.createElement(StyledIcon, { name: "arrowRight", dataTestId: "arrow-right" })))))));
};
export default InspirationalTeaserItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
